.socials {
  margin: 0 auto;
  width: fit-content;
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
}

.socials > a {
  margin: 10px;
  position: relative;
  display: inline-block;
  padding: 5px;
  padding-top: 10px;
}

.socials > a:after {
  top: 4px;
  left: 4px;
}

.socials > a:hover:after {
  top: 0px;
  left: 0px;
}

.icon {
  height: 4vh;
  width: 4vh;
  color: rgba(175, 43, 191, 0.85);
  margin: 0 1.5vh 0 1.5vh;
}
