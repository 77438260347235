@import url("https://fonts.googleapis.com/css2?family=Kosugi+Maru&display=swap");

html {
  height: 100vh;
  background: rgb(255, 154, 0);
  background: linear-gradient(
    175deg,
    rgba(255, 154, 0, 1) 0%,
    rgba(255, 188, 0, 1) 33%,
    rgba(255, 188, 0, 1) 66%,
    rgba(0, 180, 169, 1) 100%
  );
}

a {
  background-color: rgba(255, 207, 254, 0.3);
  outline: 0;
  border: 1px solid black;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

a:after {
  content: "";
  background-color: #ffe44c8d;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

a:hover:after {
  top: 0px;
  left: 0px;
}
